import type { HubConnection } from '@microsoft/signalr';
import type { AlgoOrder } from '@/types/api/algoOrders';
import { logger } from '@/helpers/logger';
import { parseTimestamp } from '@/helpers/format';
import type { AlgoUpdater } from '@/state/api/streaming/sotw.streaming';
import { formatISO } from 'date-fns';
import { isDefined, isNotDefined } from '@sgme/fp';

export function updateAlgoOrder(updater: AlgoUpdater) {
  return (currentOrder: AlgoOrder) => {
    const orderId = currentOrder.id;
    logger.logInformation(
      'Received algoOrder {orderId}: {algoOrder_s}',
      orderId,
      JSON.stringify(currentOrder),
    );
    updater(draftOrders => {
      if (isNotDefined(draftOrders)) {
        return;
      }
      const lastOrder = draftOrders[orderId];
      if (isDefined(lastOrder)) {
        const lastUpdateTime = parseTimestamp(lastOrder.updateTime);
        const receivedUpdateTime = parseTimestamp(currentOrder.updateTime);
        if (lastUpdateTime < receivedUpdateTime) {
          draftOrders[orderId] = currentOrder;
        } else {
          logger.logWarning(
            'Rejected AlgoOrder notification in the past - last update: {lastUpdateTime_s}, received update: {receivedUpdateTime_s}. {update_payload_s}',
            formatISO(lastUpdateTime),
            formatISO(receivedUpdateTime),
            JSON.stringify(currentOrder),
          );
        }
      } else {
        draftOrders[orderId] = currentOrder;
      }
    });
  };
}

export function listenToAlgoOrderStreaming(
  signalrConnection: HubConnection,
  updater: AlgoUpdater,
): () => void {
  const algoUpdater = updateAlgoOrder(updater);
  signalrConnection.on('ReceiveAlgoOrderNotification', algoUpdater);
  return () => {
    signalrConnection.off('ReceiveAlgoOrderNotification', algoUpdater);
  };
}
