import { getConfig } from '@/helpers/config';
import { bootstrapSgwtConnectListeners } from '@/helpers/logger';
import type { SGWTConnectCore, SGWTConnectError } from '@sgwt/connect-core';
import { SgwtWebAnalytics, SgwtWidgetContextProvider } from '@sgwt/sgwt-widgets-react';
import { createRoot } from 'react-dom/client';
import { App } from './components/App';
import { sgwtConnect } from './helpers/sgwtConnect';
import { bootstrapSignalRListeners, signalrConnection } from './helpers/signalr';
import { type AppStore, createStore } from './state/store';
import type { SgwtConnectHTMLElement } from './types/sgwt-widgets';
import { initSgBootstrap } from './utils/theme';

if (import.meta.env.DEV && import.meta.env.VITE_USE_MOCK === 'true') {
  import('./mock/browser').then((browser) => {
    browser.worker.start({ quiet: true, onUnhandledRequest: 'bypass' }).then(() => {
      bootstrap();
    });
  });
} else {
  setupSgwtConnectWidget(sgwtConnect);
  if (sgwtConnect.isAuthorized()) {
    bootstrap();
  } else {
    const authorizationError = sgwtConnect.getAuthorizationError();

    if (authorizationError !== null) {
      renderError(authorizationError);
    } else {
      sgwtConnect.requestAuthorization();
    }
  }
}

function bootstrap() {
  initSgBootstrap();
  bootstrapSgwtConnectListeners(sgwtConnect);
  const store = createStore();
  bootstrapSignalRListeners(signalrConnection, store.dispatch);
  renderApp(store);
}

function renderApp(store: AppStore) {
  const rootElement = document.getElementById('root');
  const root = createRoot(rootElement!);

  const {
    matomo: { site_id: sgAnalyticsSiteId, url: sgAnalyticsUrl },
  } = getConfig();

  root.render(
    <SgwtWidgetContextProvider infrastructure="azure">
      <SgwtWebAnalytics siteId={sgAnalyticsSiteId} baseUrl={sgAnalyticsUrl} />

      <App store={store} />
    </SgwtWidgetContextProvider>,
  );
}

function renderError(authorizationError: SGWTConnectError) {
  document.body.innerHTML = `
      <div class="alert alert-danger" role="alert">
        Authorization error: ${authorizationError}.
      </div>`;
}

function setupSgwtConnectWidget(sgwtConnect: SGWTConnectCore) {
  const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');
  if (widget) {
    // When the code is executed, the swgtConnectWidget may not have been initialized. So, we need to check that, otherwise calling
    // `swgtConnectWidget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      // Widget is not initialized yet, so we will wait the event that indicates the swgtConnectWidget is ready...
      const handleSgwtConnectReady = () => {
        widget.setSgwtConnectInstance(sgwtConnect);
        widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
      };

      widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
    } else {
      // Widget is initialized...
      widget.setSgwtConnectInstance(sgwtConnect);
    }
  }
}
