import Highcharts from 'highcharts';
import type { Theme } from './theme';
import { parseTimestamp } from '@/helpers/format';
import type { PickNumbersFields } from '@/helpers/typeHelpers';
import { isDefined } from '@sgme/fp';

export type Coordinate = [number, number | undefined];

const disableAxis = {
  title: {
    text: undefined,
  },
  labels: {
    enabled: false,
  },
  minorTickLength: 0,
  tickLength: 0,
};
export const disableXAxis: Pick<Highcharts.Options, 'xAxis'> = {
  xAxis: disableAxis,
};
export const disableYAxis: Pick<Highcharts.Options, 'yAxis'> = {
  yAxis: disableAxis,
};

function getCssColor(property: string) {
  return `var(--${property})`;
}

export function getHighChartsTheme(theme: Theme): Highcharts.Options {
  const backgroundColor = getCssColor('bg-lvl1');
  const primary = getCssColor('primary');
  const secondary = getCssColor('secondary');
  const colors = getColors(theme);

  return {
    credits: {
      enabled: false,
    },
    title: {
      style: {
        color: primary,
      },
    },
    chart: {
      backgroundColor,
      style: {},
    },
    xAxis: {
      tickColor: primary,
      tickLength: 1,
      lineColor: primary,
      gridLineColor: colors.gridLine,
    },
    yAxis: {
      tickColor: primary,
      tickLength: 1,
      lineColor: primary,
    },
    legend: {
      itemHoverStyle: {
        color: secondary,
      },
      align: 'right',
      verticalAlign: 'top',
      itemStyle: {
        color: primary,
        fontFamily: 'var(--font-family-sans-serif)',
        fontSize: '14px',
        fontWeight: 'normal',
      },
      itemHiddenStyle: {
        color: 'var(--gray)',
      },
    },
    navigator: {
      maskFill: 'rgba(200,200,200,0.2)',
    },
    plotOptions: {
      scatter: {
        marker: {
          color: primary,
        },
      },
    },
  };
}

type Box = [x: number, y: number, w: number, h: number];

function diamondOf(size: number) {
  return ([x, y, w, h]: Box) => [
    ['M', x + 0.5 * w, y + (0.5 - size) * h],
    ['L', x + (0.5 + size) * w, y + 0.5 * h],
    ['L', x + 0.5 * w, y + (0.5 + size) * h],
    ['L', x + (0.5 - size) * w, y + 0.5 * h],
    ['Z'],
  ];
}

if (Highcharts.SVGRenderer !== undefined) {
  function doubleDiamond(...args: Box) {
    return [...diamondOf(0.1)(args), ...diamondOf(0.5)(args)];
  }

  Highcharts.SVGRenderer.prototype.symbols['double-diamond'] = doubleDiamond;

  Highcharts.SVGRenderer.prototype.symbols['legend-diamond'] = function (...[x, y, w, h]: Box) {
    return [...doubleDiamond(x - w * 1.5, y, w, h)];
  };
}

export interface Colors {
  bid: string;
  ask: string;
  gridLine: string;
  longDash: string;
  transferPrice: string;
  average: string;
}

export function getColors(theme: Theme): Colors {
  return {
    bid: theme === 'LIGHT' ? '#00B5B6' : '#2BD7D8',
    ask: theme === 'LIGHT' ? '#C2377F' : '#F15F70',
    gridLine: theme === 'LIGHT' ? 'var(--light)' : '#3D4242',
    longDash: '#B2B2B2',
    transferPrice: '#B2B2B2',
    average: theme === 'LIGHT' ? '#D8D8D8' : '#B2B2B2',
  };
}

function isValid<K extends string, T extends HasTimestamp & { [key in K]: number | undefined }>(
  d: T,
  k: K,
) {
  return isDefined(d.timestamp) && isDefined(d[k]);
}

type HasTimestamp = { timestamp: string };

export function toCoordinates<
  K extends string,
  T extends HasTimestamp & { [key in K]: number | undefined },
>(d: T, k: K): Coordinate {
  const x = parseTimestamp(d.timestamp);
  const y = d[k];
  return [x, y];
}

export function toCoordinateTuples<
  K extends string,
  T extends HasTimestamp & { [key in K]: number | undefined },
>(data: T[], k: K): Coordinate[] {
  return data.filter(d => isValid(d, k)).map((d: T) => toCoordinates(d, k));
}

export const doubleDiamondMarker = {
  symbol: 'double-diamond',
  lineWidth: 2,
  lineColor: '#000000',
  fillColor: '#FFFFFF',
  radius: 6,
};
export const diamondMarker = {
  symbol: 'diamond',
  lineWidth: 2,
  lineColor: 'var(--primary)',
  fillColor: 'var(--primary)',
  radius: 6,
};
