import { logger } from '@/helpers/logger';
import { crashReported } from '@/state/crash/crashSlice';
import type { Dispatch } from '@reduxjs/toolkit';
import type { ErrorInfo, ReactNode } from 'react';
import { type MapDispatchToProps, connect } from 'react-redux';
import { mapStackTrace } from 'sourcemapped-stacktrace';
import { Catch } from './Utils/Catch';

export interface AppCrashConnectOwnProps {
  children: ReactNode;
}

export interface AppCrashConnectDispatchProps {
  onCatch(error: Error, errorInfo: ErrorInfo): void;
}

export const mapDispatchToPropsAppCrash: MapDispatchToProps<AppCrashConnectDispatchProps, AppCrashConnectOwnProps> = (
  dispatch: Dispatch,
) => ({
  onCatch(error: Error) {
    logger.logError('Caught react error in boundary {message_s}', error.message);

    'stack' in error &&
      mapStackTrace(error.stack, (stack) => {
        logger.logError('React error stack trace {stack_s}', stack.join('\n'));
      });

    dispatch(crashReported({ message: error.message, stack: error.stack }));
  },
});

export const AppCrash = connect(null, mapDispatchToPropsAppCrash)(Catch);
