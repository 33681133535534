import type { AppDispatch, AppState } from '@/state/store';
import { webApi } from '@/state/api/web.api';

export function fetchMissingCompaniesForBdrIds(
  bdrIds: Iterable<string>,
  state: AppState,
  dispatch: AppDispatch,
) {
  const bdrIdsToFetch: number[] = [];

  for (const bdrId of bdrIds) {
    if (state.companies?.companiesById[bdrId] === undefined) {
      const parsedBdrId = parseInt(bdrId, 10);
      if (!isNaN(parsedBdrId)) {
        bdrIdsToFetch.push(parsedBdrId);
      }
    }
  }

  if (bdrIdsToFetch.length > 0) {
    dispatch(webApi.endpoints.getCompanies.initiate(bdrIdsToFetch));
  }
}
