import { IntlProvider } from '@/context/IntlContext';
import { getConfig } from '@/helpers/config';
import { useGetUserInfoQuery } from '@/state/api/web.api';
import type { AppStore } from '@/state/store';
import type { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { AppCrash } from './AppCrash';
import { CrashModal } from './Modals/CrashModal/CrashModal';
import { PageLayout } from './layout/PageLayout';

import './App.scss';
import { useTimeout } from '@/state/hooks';
import { DuplicatedSessionModal } from './Modals/DuplicatedSessionModal/DuplicatedSessionModal';
import { ServiceLoader } from './layout/components/ServiceLoader';

export function App({ store }: { store: AppStore }) {
  return (
    <IntlProvider>
      <Provider store={store}>
        <AppCrash>
          <CheckAuthorizations>
            <PageLayout />
          </CheckAuthorizations>
        </AppCrash>
        <CrashModal />
        <DuplicatedSessionModal />
      </Provider>
    </IntlProvider>
  );
}

function CheckAuthorizations({ children }: PropsWithChildren<{}>): JSX.Element {
  const userInfo = useGetUserInfoQuery();
  const timeout = useTimeout(800);

  if (userInfo.isLoading || !timeout) {
    return <ServiceLoader />;
  }

  if (userInfo.isSuccess && Boolean(userInfo.data?.hasAccess)) {
    return <>{children}</>;
  }
  return <RedirectUnauthorized />;
}

function RedirectUnauthorized(): null {
  const { unauthorized_url } = getConfig();

  window.location.assign(unauthorized_url);
  return null;
}
