import { FormattedMessage } from 'react-intl';

export function Loading() {
  return (
    <div className="d-flex flex-center">
      <div className="spinner pt-3 me-2" role="status" />
      <div>
        <FormattedMessage id="Application.Loading" />
      </div>
    </div>
  );
}
