import { isDefined } from '@sgme/fp';

export function cleanUpSensitiveInformation(key: string, value: any) {
  if (key === 'headers') {
    if (isDefined(value) && value.Authorization !== undefined) {
      value.Authorization = '*** HIDDEN ***';
    }
  }
  return value;
}
