import { FormattedNumber } from 'react-intl';
import { getBadgeColor } from '@/domain/order';
import type { BlotterOrderStatus } from '@/types/api/algoOrders';
import { LiveOrderHeaderItem } from './LiveOrderHeaderItem';

interface LiveOrderHeaderPercentItemProps {
  title: string;
  value: number;
  status: BlotterOrderStatus;
}

export const LiveOrderHeaderPercentItem = ({
  title,
  value,
  status,
}: LiveOrderHeaderPercentItemProps): JSX.Element => {
  const badgeColor = getBadgeColor(status);
  const isInProgress = status.match(/^(Executing|Running|Updating)$/);
  return (
    <LiveOrderHeaderItem
      title={title}
      valueSupplement={
        <div className={`badge rounded-pill badge-lg badge-${badgeColor.class}`}>
          {!isInProgress ? null : (
            <i
              className="spinner-border me-1"
              style={{ width: '0.8rem', height: '0.8rem', borderWidth: '0.16rem' }}
            />
          )}
          {!badgeColor.icon ? null : <i className="icon icon-xs me-1">{badgeColor.icon}</i>}
          {status}
        </div>
      }
    >
      {/* eslint-disable-next-line react/style-prop-object */}
      <FormattedNumber value={value / 100} maximumFractionDigits={0} style="percent" />
    </LiveOrderHeaderItem>
  );
};
