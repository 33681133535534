import { noop } from '@sgme/fp';
import type { Action, Dispatch, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

export type OnActionNotifierCallback<S> = (action: any, state: S) => void;

interface IActionNotifierWhitelistMatchOrNotConfig<S> {
  didMatch: OnActionNotifierCallback<S>;
  didNotMatch: OnActionNotifierCallback<S>;
  actionsList: readonly string[];
}

interface IActionNotifierMatchAll<S> {
  didMatch: OnActionNotifierCallback<S>;
}

interface IActionNotifierWhitelistMatch<S> {
  didMatch: OnActionNotifierCallback<S>;
  actionsList: readonly string[];
}

interface IActionNotifierWhitelistNoMatch<S> {
  didNotMatch: OnActionNotifierCallback<S>;
  actionsList: readonly string[];
}
export type ActionNotifierConfig<S> =
  | IActionNotifierWhitelistMatchOrNotConfig<S>
  | IActionNotifierMatchAll<S>
  | IActionNotifierWhitelistMatch<S>
  | IActionNotifierWhitelistNoMatch<S>;

function configDefault<S>(
  config: ActionNotifierConfig<S>,
): Partial<IActionNotifierWhitelistMatchOrNotConfig<S>> {
  const conf = config as IActionNotifierWhitelistMatchOrNotConfig<S>;
  return {
    actionsList: conf.actionsList ?? undefined,
    didMatch: conf.didMatch ?? undefined,
    didNotMatch: conf.didNotMatch ?? undefined,
  };
}
export function actionNotifier<S>(config: ActionNotifierConfig<S>) {
  const { actionsList, didMatch = noop, didNotMatch = noop } = configDefault(config);
  return ((api: MiddlewareAPI<Dispatch<Action>, S>) =>
    (next: Dispatch<Action>) =>
    (action: Action) => {
      if (action.type === undefined) {
        return next(action);
      }
      if (actionsList === undefined || actionsList.includes(action.type)) {
        didMatch(action, api.getState());
      } else {
        didNotMatch(action, api.getState());
      }

      return next(action);
    }) as Middleware;
}

// TODO: filter help requests because they recursively include themselves
export const rememberLastActionsMiddleware = (actionsBuffer: Action[] = [], maxActions = 50) =>
  actionNotifier({
    didMatch: action => {
      actionsBuffer.unshift(action);
      actionsBuffer.splice(maxActions);
    },
  });
