import { isDefined } from '@sgme/fp';

export function mapLast<T, R>(array: T[], mapFn: (t: T) => R): R | undefined {
  return array.length > 0 ? mapFn(array[array.length - 1]) : undefined;
}

export function minBy<T>(array: T[], mapFn: (t: T) => number | undefined): number | undefined {
  return min(...array.map(mapFn));
}

export function maxBy<T>(array: T[], mapFn: (t: T) => number | undefined): number | undefined {
  return max(...array.map(mapFn));
}

export function max(...args: (number | undefined)[]): number | undefined {
  const nums = args.filter(isDefined);
  if (nums.length === 0) {
    return undefined;
  }
  return Math.max(...nums);
}

export function min(...args: (number | undefined)[]): number | undefined {
  const nums = args.filter(isDefined);
  if (nums.length === 0) {
    return undefined;
  }
  return Math.min(...nums);
}
