import { compareDesc, parseISO } from 'date-fns';
import { ListGroup, ListGroupItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BlotterFilter,
  getAlgoProgress,
  getBadgeColor,
  getStrategyProgress,
  matchStatus,
} from '@/domain/order';
import type { AlgoOrder } from '@/types/api/algoOrders';
import { useGetAlgoOrdersQuery } from '@/state/api/web.api';
import { selectCompany } from '@/state/companies/companiesSlice';
import { Loading } from '@/components/Utils/Loading';
import { useLatestStrategy } from '@/state/api/api.hooks';

interface OrderListProps {
  filter: BlotterFilter;
  toggleCollapsed: () => void;
}

export const OrderList = ({ filter, toggleCollapsed }: OrderListProps): JSX.Element => {
  const { isError, isLoading, data: orders } = useGetAlgoOrdersQuery();

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return (
      <div className="d-flex flex-center pt-3">
        <FormattedMessage id="LiveOrderList.NoBlotter" tagName="h2" />
      </div>
    );
  }

  const matchingOrders = Object.values(orders ?? [])
    .filter(matchStatus(filter))
    .sort((a, b) => compareDesc(parseISO(a.startTime), parseISO(b.startTime)));

  return matchingOrders.length === 0 ? (
    <div className="container my-5 text-center text-secondary">
      <em className="icon icon-xl d-inline-block pt-5 mb-2">account_balance</em>
      <FormattedMessage id="LiveOrderList.NoOrders.Title" tagName="h4" />
      <FormattedMessage id="LiveOrderList.NoOrders.Contents" tagName="p" />
    </div>
  ) : (
    <ListGroup className="list-group-flush">
      {matchingOrders?.map((order: AlgoOrder) => (
        <AlgoExecutionItem toggleCollapsed={toggleCollapsed} order={order} key={order.id} />
      ))}
    </ListGroup>
  );
};

interface AlgoExecutionItemProps {
  order: AlgoOrder;
  toggleCollapsed: () => void;
}

interface AccountNameProps {
  accountBrdId: string;
  id: string;
}

function AccountName({ accountBrdId, id }: AccountNameProps) {
  const account = useSelector(selectCompany(accountBrdId));
  const accountShortName = account?.externalCompanyName;
  if (accountShortName === undefined) {
    return null;
  }
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id={`tooltip-${id}`}>{accountShortName}</Tooltip>}
    >
      <div className="truncate">&nbsp;{`- ${accountShortName}`}</div>
    </OverlayTrigger>
  );
}

const AlgoExecutionItem = ({ order, toggleCollapsed }: AlgoExecutionItemProps): JSX.Element => {
  const badgeColor = getBadgeColor(order.status);
  const isInProgress = order.status.match(/^(Executing|Running|Updating)$/);
  const isNewStatus = order.status === 'New';

  const { orderId: selectedOrderId } = useParams<{ orderId: string | undefined }>();
  const { yourWay, currencyPair, notional, notionalCurrency, averagePrice, startTime } = order;

  const orderActive = selectedOrderId === order.id;
  const strategy = useLatestStrategy(order.id, { skip: !orderActive });

  const progress = strategy !== undefined ? getStrategyProgress(strategy) : getAlgoProgress(order);

  const history = useHistory();

  return (
    <ListGroupItem
      onDoubleClick={toggleCollapsed}
      className="d-flex border-bottom border-secondary-opacity-20 flex-between px-lg-4 px-3 cursor-pointer"
      active={orderActive}
      onClick={() => history.push(order.id)}
      data-e2e={order.id}
    >
      <div className="d-flex flex-between align-items-center w-100">
        <div className="d-flex flex-column">
          <div className="fw-semibold">
            <FormattedMessage
              id="Blotter.summary"
              values={{ yourWay, currencyPair, notional, notionalCurrency }}
            />
          </div>

          <div className="d-flex mb-2 text-secondary">
            <div>{order.orderType}</div>
            {order.account && <AccountName accountBrdId={order.account} id={order.id} />}
          </div>

          <div className="d-flex align-items-baseline">
            {isNewStatus ? (
              <span className="ms-2">
                <FormattedRelativeTime
                  unit="minute"
                  value={Math.trunc((new Date(startTime).getTime() - new Date().getTime()) / 60000)}
                ></FormattedRelativeTime>
              </span>
            ) : (
              <>
                <div className="display-4 text-figures">{progress.toFixed(0)}%</div>
                <span className="ms-2">Avg {averagePrice}</span>
              </>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center">
          <span className={`badge badge-lg rounded-pill badge-${badgeColor.class}`}>
            {!isInProgress ? null : (
              <i
                className="spinner-border me-1"
                style={{ width: '0.8rem', height: '0.8rem', borderWidth: '0.16rem' }}
              />
            )}
            {!badgeColor.icon ? null : <i className="icon icon-xs me-1">{badgeColor.icon}</i>}
            <span className='align-bottom'>
            {order.status}
            </span>
          </span>
          <i className="icon icon-lg text-secondary cursor-pointer">chevron_right</i>
        </div>
      </div>
    </ListGroupItem>
  );
};
