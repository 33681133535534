import React from 'react';
import { FormattedTime } from 'react-intl';
import { formatDistance } from 'date-fns';
import { LiveOrderHeaderItem } from './LiveOrderHeaderItem';
import type { BlotterOrderStatus } from '@/types/api/algoOrders';
import { isActive } from '@/domain/order';
import { useMediaQuery } from '@/helpers/hooks/useMediaQuery';

interface LiveOrderHeaderTimeItemProps {
  title: string;
  value: Date | number;
  currentTime: Date | number;
  status: BlotterOrderStatus;
}

export function getGmtOffset(date: Date | number) {
  return new Intl.DateTimeFormat('default', {
    timeZoneName: 'short',
  })
    .format(date)
    .split(' ')[1];
}

export const LiveOrderHeaderTimeItem = ({
  title,
  value,
  currentTime,
  status,
}: LiveOrderHeaderTimeItemProps): JSX.Element => {
  const isSmallView = useMediaQuery([['(min-width: 1200px)', false]], true);

  const isInProgress = isActive(status);
  const tz = getGmtOffset(value);

  // To transform distance "in about 1 hour" to "in ~1 hour"
  const expandedLabel = isInProgress
    ? formatDistance(value, currentTime, {
        includeSeconds: true,
        addSuffix: true,
      }).replace(/about\s/g, '~')
    : '';
  return (
    <LiveOrderHeaderItem
      title={title}
      titleValues={{ expandedLabel }}
      titleSupplement={tz}
      hasRightTitleSupplement={isSmallView}
    >
      <FormattedTime value={value} hour12={false} />
    </LiveOrderHeaderItem>
  );
};
