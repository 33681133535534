export function MiniFooter(): JSX.Element {
  return (
    <sgwt-mini-footer
      type="compact"
      design="light"
      mode="sg-markets"
      contact-us-by-help-center="sgwt-help-center"
      accessibility-compliance="none"
      accessibility-link="https://shared.sgmarkets.com/accessibility/statement/en.html?redirect_uri=https://fxtca.sgmarkets.com"
    />
  );
}
