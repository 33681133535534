import { forwardRef, useCallback, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectTheme, themeSelected } from '@/state/ui/uiSlice';
import { setTheme } from '@/helpers/theme';
import { FormattedMessage } from 'react-intl';

function ThemeSwitcher() {
  const currentTheme = useSelector(selectTheme);
  const [isChecked, setIsChecked] = useState(currentTheme !== 'DARK');


  // added this useEffect because there was a problem is the rerendering so form-switch was not working correctly when migration to SGB5
  useEffect(() => {
    setIsChecked(prevValue => !prevValue);
  }, [currentTheme]);

  return (
    <div className="form-check form-switch me-3">
      <input
        type="checkbox"
        id="themeCheckbox"
        className="form-check-input"
        checked={isChecked}
        readOnly
      />
      <label className="form-check-label">
        <FormattedMessage id="Header.darkTheme" />
      </label>
    </div>
  );
}

export function UserSettings() {
  const currentTheme = useSelector(selectTheme);
  const dispatch = useDispatch();
  const toggleTheme = useCallback(() => {
    const theme = currentTheme === 'LIGHT' ? 'DARK' : 'LIGHT';
    setTheme(theme);
    dispatch(themeSelected(theme));
  }, [currentTheme, dispatch]);

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <em className="icon icon-md">settings</em>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="1" active={false} onClick={() => toggleTheme()}>
          <ThemeSwitcher />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

interface CustomToggleProps {
  onClick: (e: any) => void;
  children: JSX.Element | JSX.Element[];
}

const CustomToggle = forwardRef<HTMLButtonElement, CustomToggleProps>(
  ({ children, onClick }, ref) => (
    <button
      className="btn btn-flat-primary btn-lg sgwt-account-center-help"
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </button>
  ),
);
