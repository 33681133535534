import { genericContext } from './GenericContext';

export const [CurrencyPairContext, CurrencyPairProvider] = genericContext(
  'currencyPair',
  '@@@_MISSING_CURRENCY_PAIR_@@@',
);

export const [NotionalCurrencyContext, NotionalCurrencyProvider] = genericContext(
  'notionalCurrency',
  '@@@_MISSING_NOTIONAL_CURRENCY_@@@',
);

export const [OrderIdContext, OrderIdProvider] = genericContext(
  'orderId',
  '@@@_MISSING_ORDER_ID_@@@',
);
