import { useFeatureToggle } from '@/state/hooks';
import type { FeatureToggleName } from '@/types/api/userInfo';
import type { ReactNode } from 'react';

interface WhenFeatureEnabledProps {
  name: FeatureToggleName;
  children: ReactNode;
}
export function WhenFeatureEnabled({
  name,
  children,
}: WhenFeatureEnabledProps): JSX.Element | null {
  const featureEnabled = useFeatureToggle(name);
  return featureEnabled ? <>{children}</> : null;
}
