import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppState } from '../store';
import { getTheme, Theme } from '@/helpers/theme';
import { selectRegisterFailed, selectRegisterIsSuccessful } from '../api/web.api';

interface UiState {
  theme: Theme;
  streamingState: StreamingState;
  blotterCollapsed: boolean;
  orderNotFound: string | null;
}

const initialState: UiState = {
  theme: getTheme(),
  streamingState: { state: 'pending' },
  blotterCollapsed: false,
  orderNotFound: null,
};

export type StreamingState =
  | StreamingConnected
  | StreamingDisconnected
  | StreamingPending
  | StreamingDuplicatedSession;

export interface StreamingConnected {
  state: 'connected';
  connectionId: string;
}
export interface StreamingPending {
  state: 'pending';
}
export interface StreamingDisconnected {
  state: 'disconnected';
}

export interface StreamingDuplicatedSession {
  state: 'duplicatedSession';
}

export const uiSlice = createSlice({
  name: 'liveTca',
  initialState,
  reducers: {
    themeSelected(state, action: PayloadAction<Theme>) {
      state.theme = action.payload;
    },
    streamingStateChanged(state, action: PayloadAction<StreamingState>) {
      state.streamingState = action.payload;
    },
    blotterToggled(state) {
      state.blotterCollapsed = !state.blotterCollapsed;
    },
    setBlotterShow(state, action: PayloadAction<boolean>) {
      state.blotterCollapsed = action.payload;
    },
    orderNotFound(state, action: PayloadAction<string>) {
      state.orderNotFound = action.payload;
    },
    clearErrorOrderNotFound(state) {
      state.orderNotFound = null;
    },
  },
});

export const {
  themeSelected,
  streamingStateChanged,
  blotterToggled,
  orderNotFound,
  clearErrorOrderNotFound,
  setBlotterShow,
} = uiSlice.actions;

export const selectTheme = (state: AppState) => state.ui.theme;
export const selectStreamingState = (state: AppState): StreamingState => state.ui.streamingState;
export const selectOrderNotFound = (state: AppState) => state.ui.orderNotFound;

type AppError = 'streamingDisconnected' | 'registerFailed' | 'orderNotFound';

export const selectAppError = (state: AppState): AppError | undefined => {
  const streamingState = selectStreamingState(state);
  const noOrderFound = selectOrderNotFound(state);
  if (streamingState.state === 'disconnected') {
    return 'streamingDisconnected';
  }
  if (streamingState.state === 'connected') {
    const registerFailed = selectRegisterFailed(state, streamingState.connectionId);
    if (registerFailed) {
      return 'registerFailed';
    }
  }
  if (noOrderFound) {
    return 'orderNotFound';
  }
  return undefined;
};

export const selectReadyForStreaming = (state: AppState): boolean => {
  const streamingState = selectStreamingState(state);
  return (
    streamingState.state === 'connected' &&
    selectRegisterIsSuccessful(state, streamingState.connectionId)
  );
};
