import { noop } from '@sgme/fp';
import { useToggle } from '@/helpers/useToggle';

export interface CollapsableProps {
  title: string | JSX.Element;
  isCollapsed: boolean;
  disabled?: boolean;
  children: JSX.Element;
  toggleCollapsed(): void;
}

export function Collapsable({
  isCollapsed,
  toggleCollapsed,
  disabled = false,
  title,
  children,
}: CollapsableProps) {
  return (
    <>
      <div className="sgbs-collapse-separator sgbs-collapse-separator-centered">
        <div className="card bg-transparent">
          <div className="card-header p-0">
            <button
              type="button"
              data-nodrag
              data-toggle="collapse"
              onClick={disabled ? noop : toggleCollapsed}
              className={`btn btn-flat-primary btn-sm btn-block justify-content-center text-nowrap p-0${isCollapsed ? ' collapsed' : ''
                }${disabled ? ' cursor-default' : ''}`}
              disabled={disabled}
            >
              <span>{title}</span>
              {disabled === false && <i className="icon icon-sm">keyboard_arrow_down</i>}
            </button>
          </div>
        </div>
      </div>
      {isCollapsed === true || disabled === true ? null : children}
    </>
  );
}

export function UncontrolledCollapsable({
  collapsed,
  ...props
}: Omit<CollapsableProps, 'isCollapsed' | 'toggleCollapsed'> & {
  collapsed?: boolean;
}) {
  const [isCollapsed, toggleCollapsed] = useToggle(collapsed);

  return <Collapsable isCollapsed={isCollapsed} toggleCollapsed={toggleCollapsed} {...props} />;
}
