import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { useEffect } from 'react';

export function PageTracker({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { sgwtWebAnalytics } = useSgwtWidgets();

  // const location = useLocation();
  // const userInfo = useGetUserInfoQuery();
  // const { locale } = useIntl();
  // const currentTheme = useSelector(selectTheme);

  useEffect(() => {
    // pushInstruction('setUserId', userInfo.data?.email);
    // pushInstruction('setCustomVariable', 1, 'locale', locale, 'visit');
    // pushInstruction('setCustomVariable', 2, 'theme', currentTheme, 'visit');
    sgwtWebAnalytics?.trackPageView();
  }, [sgwtWebAnalytics]);

  return <>{children}</>;
}
