import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { crashReported } from '../crash/crashSlice';
import { logger } from '@/helpers/logger';
import { mapStackTrace } from 'sourcemapped-stacktrace';
import { isNode } from '@/helpers/environment';

export const crashMiddleware: Middleware = (api: MiddlewareAPI) => next => action => {
  try {
    return next(action);
  } catch (e: any) {
    if (process.env.NODE_ENV !== 'production') {
      console.error('Redux error', e);
    }
    if (!isNode) {
      logger.logError('Caught redux error in crashMiddleware {message_s}', e.message);
      mapStackTrace(e.stack!, stack => {
        logger.logError('Redux error stack trace {stack_s}', stack.join('\n'));
      });
    }
    api.dispatch(crashReported({ message: e.message, stack: e.stack }));
  }
};
