import { selectStreamingState } from '@/state/ui/uiSlice';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

export function DuplicatedSessionModal(): JSX.Element {
  const isDuplicatedSession = useSelector(selectStreamingState).state === 'duplicatedSession';
  return (
    <Modal
      show={isDuplicatedSession}
      contentClassName="border border-danger"
      dialogClassName="crash-modal"
    >
      <Modal.Header>
        <Modal.Title className="text-danger">
          <FormattedMessage id="DuplicatedSessionModal.title" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage tagName="p" id="DuplicatedSessionModal.body" />
      </Modal.Body>
    </Modal>
  );
}
