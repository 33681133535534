import { ReactNode, useRef, useState, useLayoutEffect, Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import type { AlgoOrder } from '@/types/api/algoOrders';
import { LiveOrderHeader } from './LiveOrderHeader/LiveOrderHeader';
import { CurrencyPairProvider, OrderIdProvider, NotionalCurrencyProvider } from '@/context';
import { LiveOrderSpeed } from './LiveOrderSpeed';
import { useGetSotwQuery } from '@/state/api/web.api';
import { selectCompany } from '@/state/companies/companiesSlice';
import { Loading } from '@/components/Utils/Loading';
import { useSelectedOrder } from '@/state/tca/tcaSlice';
import './LiveOrder.scss';
import { useMediaQuery } from '@/helpers/hooks/useMediaQuery';
import styled from 'styled-components';
import { useSotwData } from '@/state/api/api.hooks';

const LiveOrderChart = lazy(() => import('./LiveOrderChart'));
const LiveOrderInfoPanel = lazy(() => import('./LiveOrderInfoPanel'));

export const LiveOrder = () => {
  const order = useSelectedOrder();
  if (order === undefined) {
    return (
      <div className="main container my-5 d-flex justify-content-center align-items-center text-secondary">
        <div className="text-center">
          <em className="icon icon-xl d-inline-block mb-1">timeline</em>
          <FormattedMessage id="LiveOrderChart.NoActivity.Title" tagName="h4" />
          <FormattedMessage id="LiveOrderChart.NoActivity.Contents" tagName="p" />
        </div>
      </div>
    );
  }
  return <LiveOrderPresent order={order} />;
};

interface LiveOrderProps {
  order: AlgoOrder;
}

type LiveOrderWrapperProps = {
  isSmallView: boolean;
  children: ReactNode;
  type: string;
};
type LiveOrderDetailsProps = {
  isSmallView: boolean;
  children: ReactNode;
};

interface WrapperType {
  isContentType: boolean;
}

// const div: StyledFunction<Wrapper & React.HTMLProps<HTMLDivElement>> = styled.div
const Wrapper = styled.div.attrs(({ isContentType }: WrapperType) => ({
  className: isContentType ? '' : 'position-fixed w-100 bg-lvl1 pt-3',
}))`
  ${({ isContentType }: WrapperType) =>
    isContentType ? 'padding-top: 74px;' : 'z-index: 10; top: 65px'}
`;

const LiveOrderContainer = ({
  children,
  isSmallView,
  type,
}: LiveOrderWrapperProps): JSX.Element => {
  const isContentType = type === 'main';

  if (isSmallView) {
    return <Wrapper isContentType={isContentType}>{children}</Wrapper>;
  } else {
    return <>{children}</>;
  }
};
const LiveOrderDetailsDiv = styled.div.attrs({ className: 'flex-grow-1' })`
  display: grid;
  grid-template-columns: 1fr min(30%, 740px);
  grid-template-rows: 1fr;
`;

const LiveOrderDetails = ({ children, isSmallView }: LiveOrderDetailsProps): JSX.Element => {
  if (!isSmallView) {
    return <LiveOrderDetailsDiv>{children}</LiveOrderDetailsDiv>;
  } else {
    return <div className="flex-grow-1">{children}</div>;
  }
};

const mobileViewHeight = 500;

function LiveOrderPresent({ order }: LiveOrderProps): JSX.Element {
  const { id, yourWay, currencyPair, notional, notionalCurrency } = order;
  const { isFetching } = useGetSotwQuery(id);
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const mainRef = useRef<HTMLElement>(null);
  const [reload, setReload] = useState(false);
  const [height, setHeight] = useState<number>(mobileViewHeight);
  const callbackRef = (element: HTMLDivElement) => {
    chartContainerRef.current = element;
    setReload(true);
  };
  const account = useSelector(selectCompany(order.account));
  const accountName = account?.companyName;
  const isSmallView = useMediaQuery([['(min-width: 1200px)', false]], true);

  useLayoutEffect(() => {
    if (reload && chartContainerRef.current) {
      const height = chartContainerRef.current.clientHeight;
      setHeight(isSmallView ? mobileViewHeight : height);
      window.addEventListener('resize', () => {
        const height = (chartContainerRef.current?.clientHeight || 1) - 1;
        const scrollSize = mainRef.current!.scrollHeight - mainRef.current!.clientHeight;
        if (window.innerWidth <= 1200) {
          setHeight(500);
        } else if (scrollSize > 0) {
          setHeight(0 > Math.round(height) - scrollSize ? height : Math.round(height) - scrollSize);
        } else {
          setHeight(Math.round(height));
        }
      });
    }
  }, [reload, isSmallView]);

  if (isFetching) {
    return <Loading />;
  }
  return (
    <OrderIdProvider value={id}>
      <NoRecentDataAlert />
      <CurrencyPairProvider value={order.currencyPair}>
        <main className="main p-lg-4 p-3 d-flex flex-column" ref={mainRef}>
          <LiveOrderContainer isSmallView={isSmallView} type={'header'}>
            <FormattedMessage
              id="LiveOrder.title"
              values={{ yourWay, currencyPair, notional, notionalCurrency }}
              tagName="h1"
            />
            <div className="d-flex">
              <h3 className="text-secondary mb-3">{order.orderType}</h3>
              {accountName && <h3 className="text-secondary mb-3">&nbsp;{`- ${accountName}`}</h3>}
            </div>
          </LiveOrderContainer>
          <LiveOrderContainer isSmallView={isSmallView} type={'main'}>
            <LiveOrderHeader order={order} />
            <LiveOrderDetails isSmallView={isSmallView}>
              <div
                className={`${isSmallView ? 'col-xl-7 col-12 pt-3' : 'pt-3 me-4 overflow-hidden'}`}
              >
                <FormattedMessage id="LiveOrder.activity" tagName="h2" />
                <hr className="m-0" />
                <div ref={callbackRef} className="special-height">
                  <Suspense fallback={<Loading />}>
                    <LiveOrderChart algoOrder={order} height={height} />
                  </Suspense>
                </div>

                <LiveOrderSpeed />
              </div>
              <div className={`${isSmallView ? 'col-xl-5 col-12 ' : ''}d-flex flex-column`}>
                <Suspense fallback={<Loading />}>
                  <NotionalCurrencyProvider value={order.notionalCurrency}>
                    <LiveOrderInfoPanel />
                  </NotionalCurrencyProvider>
                </Suspense>
              </div>
            </LiveOrderDetails>
          </LiveOrderContainer>
        </main>
      </CurrencyPairProvider>
    </OrderIdProvider>
  );
}

function NoRecentDataAlert() {
  const noRecentData = useSotwData(data => data.noRecentData);
  return noRecentData ? (
    <div className="sgwt-account-center">
      <div className="sgwt-account-center-tags">
        <div className="sgwt-account-center-tag p-1 alert-warning">
          <FormattedMessage id="Header.noRecentData" />
        </div>
      </div>
    </div>
  ) : null;
}
