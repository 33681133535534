import type { Strategy } from '@/types/api/sotw';
import { useGetSotwQuery } from '@/state/api/web.api';
import type { AlgoExecutionState } from '@/state/api/mapAlgoExecutionState';
import { useContext } from 'react';
import { OrderIdContext } from '@/context';

interface StrategyOpts {
  skip: boolean;
}

export function useSotwData<R>(
  mapFn: (t: AlgoExecutionState) => R,
  opts: StrategyOpts = { skip: false },
): R | undefined {
  const orderId = useContext(OrderIdContext);
  const response = useGetSotwQuery(orderId, { skip: opts.skip });
  if (response.isSuccess && !response.isLoading) {
    return mapFn(response.data);
  }
  return undefined;
}

export function useLatestStrategy(
  orderId: string,
  opts: StrategyOpts = { skip: false },
): Strategy | undefined {
  const response = useGetSotwQuery(orderId, { skip: opts.skip });
  if (response.isSuccess && !response.isLoading) {
    return response.data.strategies.latestStrategy;
  }
  return undefined;
}
