export type PascalCaseObject<T> = {
  [K in keyof T as Capitalize<string & K>]: T[K];
};
export type JsCaseObject<T> = {
  [K in keyof T as Uncapitalize<string & K>]: T[K];
};

export function toJsCase<T extends {}>(t: T): JsCaseObject<T> {
  return Object.fromEntries(
    Object.entries(t)
      .map(([key, value]) => [
        key.replace(/^./, key[0].toLowerCase()),
        value instanceof Date ? value.toISOString() : value,
      ]),
  ) as JsCaseObject<T>;
}
