import { createContext } from 'react';

export function genericContext<K extends string, T>(key: K, defaultValue: T) {
  const pascalCasedKey = key.charAt(0).toUpperCase() + key.slice(1);

  const GenericContext = createContext(defaultValue);
  GenericContext.displayName = `${pascalCasedKey}Context`;

  return [GenericContext, GenericContext.Provider] as const;
}
