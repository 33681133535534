import React, { forwardRef, PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from '@/helpers/hooks/useMediaQuery';

interface LiveOrderHeaderItemProps {
  title: string;
  titleValues?: Record<string, ReactNode>;
  titleSupplement?: ReactNode;
  valueSupplement?: ReactNode;
  hasRightTitleSupplement?: boolean;
  onFocus?(): void;
  onBlur?(): void;
  onMouseOver?(): void;
  onMouseOut?(): void;
}

export const LiveOrderHeaderItem = forwardRef<
  HTMLDivElement,
  PropsWithChildren<LiveOrderHeaderItemProps>
>(
  (
    {
      title,
      titleValues,
      titleSupplement,
      children,
      valueSupplement,
      hasRightTitleSupplement = false,
      ...handlers
    },
    ref,
  ) => {
    const isSmallView = useMediaQuery([['(min-width: 1200px)', false]], true);

    return (
      <div
        className={`col card bg-secondary-opacity-05 card-filled full-height d-flex flex-${
          !isSmallView ? 'column m-1' : 'row align-items-center mx-1 mt-1 mb-0'
        } justify-content-between p-3`}
        style={isSmallView ? { height: '44px' } : {}}
        ref={ref}
        {...handlers}
        data-e2e={title}
      >
        <div className="d-flex justify-content-between align-items-baseline">
          <div className={`text-secondary ${!isSmallView && 'mb-lg-4 mb-3'} fw-medium`}>
            <FormattedMessage id={`LiveOrderHeader.${title}.title`} values={titleValues} />
          </div>
          {titleSupplement && !hasRightTitleSupplement && (
            <div className="text-secondary fw-bold">{titleSupplement}</div>
          )}
        </div>
        <div
          className={`d-flex justify-content-between align-items-${
            !isSmallView ? 'baseline' : 'center'
          } flex-wrap-reverse`}
        >
          <div
            className={`line-height-1 ${
              isSmallView ? 'h5 order-2 fw-normal ms-3 mb-0' : 'responsive-figure'
            }`}
          >
            {children}
          </div>
          {valueSupplement && <div>{valueSupplement}</div>}
          {titleSupplement && hasRightTitleSupplement && (
            <div className="text-secondary fw-bold order-2 ms-2">{titleSupplement}</div>
          )}
        </div>
      </div>
    );
  },
);
