import type { FeatureToggleName } from '@/types/api/userInfo';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useGetUserInfoQuery } from './api/web.api';
import type { AppState, AppDispatch } from './store';
import { useEffect, useState } from 'react';

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useFeatureToggle = (name: FeatureToggleName) => {
  const result = useGetUserInfoQuery();
  if (result.isSuccess === false) {
    return false;
  }
  return result.data.featureToggles[name];
};

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;



export const useTimeout = (durationInMilliseconds: number) => {
  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTimeout(true);
    }, durationInMilliseconds);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return isTimeout
};
