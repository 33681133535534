import { getStrategyProgress } from '@/domain/order';
import type { AlgoOrder } from '@/types/api/algoOrders';
import type { Strategy } from '@/types/api/sotw';
import { LiveOrderHeaderAmountItem } from './LiveOrderHeaderAmountItem';
import { LiveOrderHeaderAverageItem } from './LiveOrderHeaderAverageItem';
import { LiveOrderHeaderPercentItem } from './LiveOrderHeaderPercentItem';
import { LiveOrderHeaderRateItem } from './LiveOrderHeaderRateItem';
import { LiveOrderHeaderTimeItem } from './LiveOrderHeaderTimeItem';
import { useSotwData } from '@/state/api/api.hooks';
import { parseTimestamp } from '@/helpers/format';
import { LiveOrderHeaderItem } from './LiveOrderHeaderItem';
import { useMediaQuery } from '@/helpers/hooks/useMediaQuery';

interface LiveOrderHeaderProps {
  order: AlgoOrder;
}

const smallViewStyles = 'flex-wrap justify-content-between';

export function LiveOrderHeader({ order }: LiveOrderHeaderProps): JSX.Element {
  const strategy = useSotwData(data => data.strategies.latestStrategy);
  const isSmallView = useMediaQuery([['(min-width: 1200px)', false]], true);

  return (
    <div className={`d-flex ${isSmallView ? smallViewStyles : ''} mb-lg-3 mb-2 mx-n1`}>
      {strategy === undefined ? <EmptyPlaceholders /> : <KeyPerformanceIndicators order={order} strategy={strategy} />}
    </div>
  );
}

type KeyPerformanceIndicatorsProps = LiveOrderHeaderProps & {
  strategy: Strategy;
};

// @ts-ignore
const ItemDiv = ({ children }): JSX.Element => {
  const isSmallView = useMediaQuery([['(min-width: 1200px)', false]], true);

  if (isSmallView) {
    return <div style={{ width: '49.8%' }}>{children}</div>;
  } else {
    return <>{children}</>;
  }
};

function KeyPerformanceIndicators({ order, strategy }: KeyPerformanceIndicatorsProps): JSX.Element {
  return (
    <>
      <ItemDiv>
        <LiveOrderHeaderRateItem
          title="arrival"
          price={strategy.arrivalPrice!}
          perfBps={strategy.arrivalPerfBps!}
          perfCurrency={strategy.arrivalPerfCcy!}
          currency={strategy.requestedCcy}
        />
      </ItemDiv>
      <ItemDiv>
        <LiveOrderHeaderRateItem
          title="transfer"
          price={strategy.transferPrice!}
          perfBps={strategy.transferPerfBps!}
          perfCurrency={strategy.transferPerfCcy!}
          currency={strategy.requestedCcy}
        />
      </ItemDiv>
      <ItemDiv>
        <LiveOrderHeaderRateItem
          title="weighted"
          price={strategy.weightedMidPrice!}
          perfBps={strategy.weightedMidPerfBps!}
          perfCurrency={strategy.weightedMidPerfCcy!}
          currency={strategy.requestedCcy}
        />
      </ItemDiv>
      <ItemDiv>
        <LiveOrderHeaderAverageItem title="average" value={strategy.executedNetPrice!} />
      </ItemDiv>
      <ItemDiv>
        <LiveOrderHeaderAmountItem
          title="executed"
          currency={order.notionalCurrency}
          value={strategy.executedQuantity ?? 0}
        />
      </ItemDiv>
      <ItemDiv>
        <LiveOrderHeaderPercentItem
          title="progress"
          value={getStrategyProgress(strategy)}
          status={order.status}
        />
      </ItemDiv>
      <ItemDiv>
        {strategy.expectedEndTime && (
          <LiveOrderHeaderTimeItem
            title="eta"
            value={parseTimestamp(strategy.expectedEndTime)}
            currentTime={parseTimestamp(strategy.timestamp)}
            status={order.status}
          />
        )}
      </ItemDiv>
    </>
  );
}

function EmptyPlaceholders(): JSX.Element {
  const sections = ['arrival', 'transfer', 'weighted', 'average', 'executed', 'progress'];

  // the invisible 🏢 icon ensures the placeholder has same height as normal component
  return (
    <>
      {sections.map(title => (
        <LiveOrderHeaderItem title={title} key={title}>
          <i className="icon invisible" aria-hidden="true">
            business
          </i>
        </LiveOrderHeaderItem>
      ))}
    </>
  );
}
