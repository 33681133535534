import { ErrorInfo, PureComponent } from 'react';

interface CatchState {
  hasError: boolean;
}

interface CatchProps {
  onCatch(error: Error, errorInfo: ErrorInfo): void;
  children: JSX.Element | JSX.Element[];
}

export class Catch extends PureComponent<CatchProps, CatchState> {
  public state = { hasError: false };
  public static getDerivedStateFromError() {
    return { hasError: true };
  }
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.onCatch(error, errorInfo);
  }
  public render() {
    return this.state.hasError ? null : this.props.children;
  }
}
