import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppState } from '../store';

interface Exception {
  message: string;
  stack?: string;
}

interface CrashState {
  error?: Exception;
}

const initialState: CrashState = {};

export const crashSlice = createSlice({
  name: 'crash',
  initialState,
  reducers: {
    crashReported(state, action: PayloadAction<Exception>) {
      state.error = action.payload;
    },
  },
});

export const selectCrashError = (state: AppState) => state.crash.error;

export const { crashReported } = crashSlice.actions;
