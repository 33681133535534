import { webApi } from '@/state/api/web.api';
import { createSlice } from '@reduxjs/toolkit';
import type { UserAccount } from '@/types/api/userInfo';
import type { AppState } from '@/state/store';

interface CompaniesState {
  companiesById: Record<string, UserAccount>;
}

const initialState: CompaniesState = {
  companiesById: {},
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(webApi.endpoints.getUserInfo.matchFulfilled, (state, action) => {
      state.companiesById = Object.fromEntries(
        action.payload.clients.map(account => [String(account.companyBdrId), account]),
      );
    });
    builder.addMatcher(webApi.endpoints.getCompanies.matchFulfilled, (state, action) => {
      for (const [bdrId, company] of Object.entries(action.payload)) {
        state.companiesById[bdrId] = company;
      }
    });
  },
});

export const selectCompany =
  (companyBdrId: string) =>
  (state: AppState): UserAccount | undefined =>
    state.companies.companiesById[companyBdrId];
