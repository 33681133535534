import type { BackendTcaOrderDto, Deal, MarketData, Order, Strategy } from '@/types/api/sotw';
import { Coordinate, toCoordinates, toCoordinateTuples } from '@/helpers/highcharts';
import { maxBy, minBy } from '@/helpers/functions';
import { isDefined } from '@sgme/fp';

export interface AlgoExecutionState {
  noRecentData: boolean;
  deals: AlgoExecutionDeals;
  marketData: AlgoExecutionMarketData;
  orders: Order[];
  strategies: AlgoExecutionStrategy;
}

export interface AlgoExecutionMarketData {
  bid: Coordinate[];
  ask: Coordinate[];
  minBid: number | undefined;
  maxAsk: number | undefined;
}

export interface AlgoExecutionStrategy {
  latestStrategy: Strategy | undefined;
  average: Coordinate[];
  transferPrice: Coordinate[];
  requestedPrice: Coordinate[];
}

export interface AlgoExecutionDeals {
  passive: Coordinate[];
  aggressive: Coordinate[];
  aggressiveDeals: Deal[];
  passiveDeals: Deal[];
}

export function isValidAverage(grossPrice: number | undefined): boolean {
  return isDefined(grossPrice) && grossPrice !== 0;
}

export function mapAlgoExecutionState({
  deals,
  marketData,
  orders,
  strategies,
}: BackendTcaOrderDto): AlgoExecutionState {
  return {
    noRecentData: false,
    deals: mapExecutionDeals(deals),
    marketData: mapExecutionMarketData(marketData),
    strategies: mapExecutionStrategy(strategies),
    orders,
  };
}

function mapExecutionMarketData(marketData: MarketData[]): AlgoExecutionMarketData {
  const bid = toCoordinateTuples(marketData, 'bid');
  const ask = toCoordinateTuples(marketData, 'ask');
  const minBid = minBy(bid, b => b[1]);
  const maxAsk = maxBy(ask, b => b[1]);
  return {
    bid,
    ask,
    minBid,
    maxAsk,
  };
}

export function mapExecutionStrategy(strategies: Strategy[]): AlgoExecutionStrategy {
  const latestStrategy = strategies[strategies.length - 1];
  const relevantStrategies = strategies.filter(s => s.status !== 'New');
  const average = toCoordinateTuples(
    relevantStrategies.filter(s => isValidAverage(s.executedGrossPrice)),
    'executedGrossPrice',
  );
  const transferPrice = toCoordinateTuples(relevantStrategies, 'transferPrice');
  const requestedPrice = toCoordinateTuples(relevantStrategies, 'requestedPrice');
  return {
    latestStrategy,
    average,
    transferPrice,
    requestedPrice,
  };
}

function mapExecutionDeals(deals: Deal[]): AlgoExecutionDeals {
  const aggressive = [];
  const passive = [];
  const aggressiveDeals = [];
  const passiveDeals = [];
  for (const deal of deals) {
    if (deal.orderType === 'Passive') {
      passive.push(toCoordinates(deal, 'grossPrice'));
      passiveDeals.push(deal);
    } else {
      aggressive.push(toCoordinates(deal, 'grossPrice'));
      aggressiveDeals.push(deal);
    }
  }
  return {
    aggressive,
    passive,
    aggressiveDeals,
    passiveDeals,
  };
}
