import type { AlgoOrder, BlotterOrderStatus } from '@/types/api/algoOrders';
import type { Order, Strategy } from '@/types/api/sotw';
import { parseTimestamp } from '@/helpers/format';
import { isToday } from 'date-fns';

export const getAlgoProgress = (order: AlgoOrder) => (order.executedAmount / order.notional) * 100;

export const getStrategyProgress = (strategy: Strategy) => {
  if (strategy.executedQuantity === undefined || strategy.requestedQuantity === undefined) {
    return 0;
  }
  return (strategy.executedQuantity / strategy.requestedQuantity) * 100;
};

export function getBadgeColor(status: BlotterOrderStatus): { class: string; icon: string | null } {
  switch (status) {
    case 'Executing':
    case 'Running':
    case 'Updating':
      return {
        class: 'info',
        icon: null,
      };
    case 'Watched':
      return {
        class: 'discreet-info',
        icon: 'adjust',
      };
    case 'Paused':
      return {
        class: 'discreet-info',
        icon: 'pause',
      };
    case 'Cancelling':
    case 'Cancelled':
      return {
        class: 'outline-danger',
        icon: 'clear',
      };
    case 'Rejected':
      return {
        class: 'outline-danger',
        icon: 'block',
      };
    case 'Expired':
      return {
        class: 'outline-secondary',
        icon: 'history_toggle_off',
      };
    case 'In error':
      return {
        class: 'outline-danger',
        icon: 'error_outline',
      };
    case 'Partially done':
    case 'Done':
      return {
        class: 'outline-success',
        icon: 'done',
      };
    case 'Finished':
      return {
        class: 'outline-success',
        icon: 'done_all',
      };
    case 'Submitted':
      return {
        class: 'discreet-warning',
        icon: 'hourglass_empty',
      };
    case 'Scheduled':
      return {
        class: 'discreet-info',
        icon: 'schedule',
      };
    default:
      return {
        class: 'secondary',
        icon: null,
      };
  }
}

export const blotterFilters = ['today', 'active', 'all'] as const;
export type BlotterFilter = typeof blotterFilters[number];

export function isActive(orderStatus: BlotterOrderStatus) {
  return (
    orderStatus === 'New' ||
    orderStatus === 'Submitted' ||
    orderStatus === 'Watched' ||
    orderStatus === 'Executing' ||
    orderStatus === 'Paused' ||
    orderStatus === 'Running' ||
    orderStatus === 'Updating'
  );
}

export function matchStatus(currentFilter: BlotterFilter): (order: AlgoOrder) => boolean {
  return function (order) {
    switch (currentFilter) {
      case 'today':
        return isToday(parseTimestamp(order.startTime));
      case 'all':
        return true;
      case 'active':
        return isActive(order.status);
    }
  };
}

export function getOrderList(orders: readonly Order[]) {
  return Object.values(
    orders.reduce((acc, order) => {
      const previousOrder = acc[order.orderSpolRef];
      if (
        previousOrder === undefined ||
        parseTimestamp(order.timestamp) > parseTimestamp(previousOrder.timestamp)
      ) {
        acc[order.orderSpolRef] = order;
      }
      return acc;
    }, {} as Record<number, Order>),
  ).sort((x, y) => parseTimestamp(y.timestamp) - parseTimestamp(x.timestamp));
}
