type DateLike = string | number | Date | null | undefined;

export function formatNumber(value: number, locale: string, options?: Intl.NumberFormatOptions) {
  // replace nbsp by real spaces
  return Intl.NumberFormat(locale, options).format(value).replace(/\s/g, ' ');
}

export function formatDate(value: DateLike, locale: string) {
  const parsed = parseDateLike(value);
  if (isNaN(parsed)) {
    return '-';
  }
  return Intl.DateTimeFormat(locale, {
    dateStyle: 'medium',
  }).format(parsed);
}

export function formatDateAndTime(value: DateLike, locale: string) {
  const parsed = parseDateLike(value);
  if (isNaN(parsed)) {
    return '-';
  }
  return Intl.DateTimeFormat(locale, {
    dateStyle: 'medium',
    timeStyle: 'long',
    hour12: false,
  }).format(parsed);
}

export function formatTime(value: DateLike, locale: string) {
  const parsed = parseDateLike(value);
  if (isNaN(parsed)) {
    return '-';
  }
  return Intl.DateTimeFormat(locale, {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(parsed);
}

function parseDateLike(value: DateLike): number {
  if (value === null) {
    return NaN;
  }
  switch (typeof value) {
    case 'number':
      return value;
    case 'undefined':
      return NaN;
    case 'string':
      return Date.parse(value);
    default:
      return value.getTime();
  }
}

export function parseTimestamp(timestampISO: string): number {
  const parsedTimestamp = Date.parse(timestampISO);
  if (isNaN(parsedTimestamp)) {
    throw new Error('timestamp is not an ISO string');
  }
  return parsedTimestamp;
}

export function getTimezoneOffset() {
  return new Date().getTimezoneOffset();
}
