import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '@/helpers/config';
import type { AlgoOrder } from '@/types/api/algoOrders';
import { getAuthorizationHeader, useFakeAuthentication } from '@/helpers/auth';
import type { AppState } from '../store';
import type { SendHelpRequestParams } from '@/types/api/sendHelpRequest';
import type { UserAccount, UserInfo } from '@/types/api/userInfo';
import { listenToSotwStreaming } from '@/state/api/streaming/sotw.streaming';
import { signalrConnection } from '@/helpers/signalr';
import { AlgoExecutionState, mapAlgoExecutionState } from '@/state/api/mapAlgoExecutionState';
import { listenToAlgoOrderStreaming } from '@/state/api/streaming/algo.streaming';
import { logger } from '@/helpers/logger';
import { fetchMissingCompaniesForBdrIds } from '@/state/companies/fetchMissingCompaniesForBdrIds';

export const webApi = createApi({
  reducerPath: 'webApi',
  tagTypes: ['algoOrder', 'sotw'],

  baseQuery: fetchBaseQuery({
    baseUrl: getConfig().api_url,
    prepareHeaders: headers => {
      const authorizationHeader = getAuthorizationHeader();
      if (authorizationHeader) {
        headers.set('Authorization', authorizationHeader);
      }
      // eslint-disable-next-line react-hooks/rules-of-hooks
      if (useFakeAuthentication()) {
        // this is only used to by-pass Ulysse user rights and force FX TCA flag = true
        headers.set('X-Fake-Authentication', 'dow.jones@sgcib.com');
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    getAlgoOrders: builder.query<Record<string, AlgoOrder>, void>({
      query: () => `api/blotter/algoOrders`,
      providesTags: ['algoOrder'],
      transformResponse: (algoOrders: AlgoOrder[]) =>
        Object.fromEntries(algoOrders.map(order => [order.id, order])),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;

          signalrConnection.on('ReceiveAlgoOrderNotification', (current: AlgoOrder) => {
            fetchMissingCompaniesForBdrIds(
              [current.account],
              getState() as unknown as AppState,
              dispatch,
            );
          });

          const bdrIds = new Set(Object.values(data).map(algo => algo.account));
          const state = getState() as unknown as AppState;

          fetchMissingCompaniesForBdrIds(bdrIds, state, dispatch);
        } catch (e: any) {
          logger.logError(
            'Error when fetching companies {error_message_s}',
            e.message ?? e?.error?.error ?? String(e),
          );
        }
      },
      async onCacheEntryAdded(_orderId, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        await cacheDataLoaded;
        const unsubscribe = listenToAlgoOrderStreaming(signalrConnection, updateCachedData);

        await cacheEntryRemoved;
        unsubscribe();
      },
    }),
    getSotw: builder.query<AlgoExecutionState, string>({
      query: (orderId: string) => `api/sotw?orderId=${orderId}`,
      providesTags: ['sotw'],
      transformResponse: mapAlgoExecutionState,
      keepUnusedDataFor: 0,
      async onCacheEntryAdded(
        orderId,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getCacheEntry },
      ) {
        await cacheDataLoaded;
        const latestStrategy = getCacheEntry().data?.strategies.latestStrategy;
        if (latestStrategy?.status !== 'Done') {
          const unsubscribe = listenToSotwStreaming(signalrConnection, orderId, updateCachedData);

          await cacheEntryRemoved;
          unsubscribe();
        }
      },
    }),
    postNotificationRegister: builder.query<void, string>({
      query: connectionId => ({
        url: `api/notification/register?connectionId=${connectionId}`,
        method: 'POST',
      }),
    }),
    sendHelpRequest: builder.mutation<void, SendHelpRequestParams>({
      query: body => ({ url: 'api/help/request', method: 'POST', body }),
    }),
    invalidateCache: builder.mutation<null, void>({
      // The query is not relevant here, so a `null` returning `queryFn` is used
      queryFn: () => ({ data: null }),
      invalidatesTags: ['sotw', 'algoOrder'],
    }),
    getUserInfo: builder.query<UserInfo, void>({
      query: () => `api/user/current`,
    }),
    getCompanies: builder.query<Record<string, UserAccount>, number[]>({
      query: companyBdrIds => ({ url: 'api/companies', method: 'POST', body: companyBdrIds }),
    }),
  }),
});

export const selectRegisterIsSuccessful = (state: AppState, connectionId: string) =>
  webApi.endpoints.postNotificationRegister.select(connectionId)(state).isSuccess;

export const selectRegisterFailed = (state: AppState, connectionId: string) =>
  webApi.endpoints.postNotificationRegister.select(connectionId)(state).isError;

export const selectSotw = (orderId: string) => (state: AppState) =>
  webApi.endpoints.getSotw.select(orderId)(state).data;

export const {
  useGetAlgoOrdersQuery,
  useGetSotwQuery,
  useSendHelpRequestMutation,
  useGetUserInfoQuery,
  useGetCompaniesQuery,
} = webApi;
