import { LogBuilder } from '@sgme/logger';
import { isNode } from '@/helpers/environment';
import { sgwtConnect } from '@/helpers/sgwtConnect';
import { getConfig } from '@/helpers/config';
import type { SGWTConnectCore } from '@sgwt/connect-core';

let logBuilder = LogBuilder();
if (!isNode) {
  if (process.env.NODE_ENV === 'production') {
    logBuilder = logBuilder
      .enableConsole({ minLevel: 'WARN' })
      .enableServerLogs({
        storeName: 'main',
        url: `${getConfig().api_url}/api/log`,
        prefix: '[FxTCA]',
      })
      .setAuthTokenFactory(() => {
        if (sgwtConnect.isAuthorized()) {
          const authToken = sgwtConnect.getAuthorizationHeader();
          if (authToken) return authToken;
        }
        throw new Error('No authorization header or token. Disconnected ?!');
      });
  } else {
    logBuilder = logBuilder.enableConsole({ minLevel: 'WARN' });
  }
}

export const logger = logBuilder.build();

export function bootstrapSgwtConnectListeners(sgwtConnect: SGWTConnectCore) {
  function onUserConnected(): void {
    logger.resumeServerLogs();
  }

  function onUserDisconnected(): void {
    logger.pauseServerLogs();
  }

  sgwtConnect.on('authorizationDiscarded', onUserDisconnected);
  sgwtConnect.on('authorizationExpired', onUserDisconnected);
  sgwtConnect.on('renewAuthorizationError', onUserDisconnected);
  sgwtConnect.on('renewAuthorizationSuccess', onUserConnected);
}
