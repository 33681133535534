import { FormattedNumber } from 'react-intl';
import { usePrecision } from '@/context/usePrecision';
import { LiveOrderHeaderItem } from './LiveOrderHeaderItem';

interface LiveOrderHeaderAverageItemProps {
  title: string;
  value: number;
}

export function LiveOrderHeaderAverageItem({ title, value }: LiveOrderHeaderAverageItemProps) {
  const precision = usePrecision();
  return (
    <LiveOrderHeaderItem title={title}>
      <FormattedNumber value={value} maximumFractionDigits={precision + 1} />
    </LiveOrderHeaderItem>
  );
}
