import { logger } from '@/helpers/logger';
import { webApi } from '@/state/api/web.api';
import type { AppDispatch } from '@/state/store';
import { streamingStateChanged } from '@/state/ui/uiSlice';
import { type HubConnection, HubConnectionBuilder, LogLevel, type RetryContext } from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { getAuthorizationError, getAuthorizationToken } from './auth';
import { getConfig } from './config';

const {
  signalR: { hub_url, reconnectDelay },
} = getConfig();

function createSignalrConnection(): HubConnection {
  const authorizationTokenLength = getAuthorizationToken()?.length ?? 0;

  logger.logInformation('Checking authorizationToken length {tokenLength_n}', authorizationTokenLength);

  if (authorizationTokenLength === 0) {
    logger.logError('Failed to get authorizationToken because its empty');
  }

  const authError = getAuthorizationError();

  if (authError) {
    logger.logError('Failed to get authorizationToken because ', JSON.stringify(authError));
  }

  return new HubConnectionBuilder()
    .withUrl(hub_url, {
      accessTokenFactory: () => getAuthorizationToken() ?? '',
    })
    .withHubProtocol(new MessagePackHubProtocol())
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: (retryContext: RetryContext) => {
        const {
          previousRetryCount,
          elapsedMilliseconds,
          retryReason: { stack: retryReasonStack },
        } = retryContext;

        logger.logWarning(
          'Attempt to auto reconnect. Retry count: {previousRetryCount_n}. Time spend reconnecting in ms: {elapsedMilliseconds_n}. Reason: {retryReasonStack_s}',
          previousRetryCount,
          elapsedMilliseconds,
          JSON.stringify(retryReasonStack),
        );

        return retryContext.elapsedMilliseconds < 60 * 1000 ? reconnectDelay : reconnectDelay * 10;
      },
    })
    .build();
}

export type MockedHubConnection = HubConnection & {
  mockMessage(methodName: string, data: any): void;
};

function createMockedSignalrConnection(): MockedHubConnection {
  const listeners = new Map();

  return {
    start() {
      return Promise.resolve();
    },
    stop() {
      return Promise.resolve();
    },
    onreconnected() {},
    onclose() {},
    on(methodName: string, listener: (...args: any[]) => void) {
      listeners.set(methodName, listener);
    },
    off(methodName: string) {
      listeners.delete(methodName);
    },
    mockMessage(methodName: string, data: any) {
      listeners.get(methodName)?.(data);
    },
  } as unknown as MockedHubConnection;
}

export const signalrConnection: HubConnection =
  import.meta.env.VITE_USE_MOCK === 'true' ? createMockedSignalrConnection() : createSignalrConnection();

export function bootstrapSignalRListeners(signalrConnection: HubConnection, dispatch: AppDispatch) {
  let retryCount = 0;
  const retryDelayMs = 2_500;

  const startConnection = () => logger.logInformation('Starting signalr connection...');

  signalrConnection.start().then(
    () => {
      const connectionId = signalrConnection.connectionId!;

      logger.logInformation('Established signalr connection for {connectionId}', connectionId);

      dispatch(webApi.endpoints.postNotificationRegister.initiate(connectionId));
      dispatch(streamingStateChanged({ state: 'connected', connectionId }));

      retryCount = 0;
    },
    (reason: any) => {
      logger.logError(
        // There is already a field ConnectionId set by the backend (use signalrConnectionId instead of connectionId field here)
        'Fail to establish signalR connection for {signalrConnectionId} because {signalRConnectionFailureReason} attempt count {retryCount}',
        signalrConnection.connectionId,
        reason,
        retryCount,
      );

      setTimeout(startConnection, retryDelayMs);
    },
  );

  window.addEventListener('offline', async () => {
    logger.logInformation('window offline event has been trigger. Stopping signalr ...');

    await signalrConnection.stop();
  });

  window.addEventListener('online', () => {
    logger.logInformation('window online event has been trigger. Starting signalr ...');
    startConnection();
  });

  startConnection();

  signalrConnection.onreconnected((connectionId = signalrConnection.connectionId!) => {
    logger.logWarning('Re-established signalr connection for {connectionId}', connectionId);

    dispatch(webApi.endpoints.invalidateCache.initiate());
    dispatch(webApi.endpoints.postNotificationRegister.initiate(connectionId));
    dispatch(streamingStateChanged({ state: 'connected', connectionId }));
  });

  signalrConnection.onclose(() => {
    logger.logError(
      'Lost signalr connection for {connectionId}. Try to restart signalr ...',
      signalrConnection.connectionId,
    );

    dispatch(webApi.endpoints.invalidateCache.initiate());
    dispatch(streamingStateChanged({ state: 'disconnected' }));

    // Try to reconnect right away
    startConnection();
  });

  signalrConnection.on('ReceiveDuplicatedConnection', () => {
    logger.logWarning('Duplicate signalr connection for {connectionId}', signalrConnection.connectionId);

    dispatch(streamingStateChanged({ state: 'duplicatedSession' }));
  });
}
