import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import { tcaSlice } from './tca/tcaSlice';
import { webApi } from './api/web.api';
import { uiSlice } from './ui/uiSlice';
import { crashSlice } from './crash/crashSlice';
import { rememberLastActionsMiddleware } from './middlewares/rememberLastActionsMiddleware';
import { crashMiddleware } from './middlewares/crashMiddleware';
import { query } from '@/helpers/query';
import { logApiMiddleware } from '@/state/middlewares/logApiMiddleware';
import { companiesSlice } from './companies/companiesSlice';

const reducer = combineReducers({
  liveTca: tcaSlice.reducer,
  ui: uiSlice.reducer,
  [webApi.reducerPath]: webApi.reducer,
  crash: crashSlice.reducer,
  companies: companiesSlice.reducer,
});

const actions: Action[] = [];

export const createStore = (preloadedState: any = {}) =>
  configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
        .prepend(crashMiddleware, logApiMiddleware, rememberLastActionsMiddleware(actions))
        .concat(webApi.middleware),
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production' || query.has('devtools'),
  });

export type AppStore = ReturnType<typeof createStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

export const getStoreLastActions = () => actions;
