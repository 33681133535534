import { useCallback, useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import { Blotter } from './Blotter/Blotter';
import { LiveOrder } from './LiveOrder/LiveOrder';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearErrorOrderNotFound,
  setBlotterShow,
  orderNotFound,
  selectAppError,
  selectOrderNotFound,
} from '@/state/ui/uiSlice';
import { FormattedMessage } from 'react-intl';
import { selectOrder } from '@/state/tca/tcaSlice';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '@/state/hooks';
import { useGetAlgoOrdersQuery } from '@/state/api/web.api';
import { useMediaQuery } from '@/helpers/hooks/useMediaQuery';

const Notifications = (): JSX.Element => {
  const appError = useSelector(selectAppError);
  const displayed = appError !== undefined;

  const [show, setShow] = useState(displayed);
  useEffect(() => {
    setShow(displayed);
  }, [displayed]);

  const dispatch = useDispatch();
  const toggle = useCallback(() => {
    setShow(oldShow => !oldShow);
    if (appError === 'orderNotFound') {
      dispatch(clearErrorOrderNotFound());
    }
  }, [appError, dispatch]);

  const orderNotFoundId = { orderId: useAppSelector(selectOrderNotFound) };

  return (
    <Toast className="toast-danger" id="notifications" show={show} onClose={toggle}>
      <Toast.Header closeButton>
        <FormattedMessage id="Application.Error.title" />
      </Toast.Header>
      <Toast.Body>
        <FormattedMessage id={`Application.Error.${appError}`} values={orderNotFoundId} />
      </Toast.Body>
    </Toast>
  );
};

function RedirectIfOrderNotFound() {
  const { isLoading } = useGetAlgoOrdersQuery();

  const { orderId: selectedOrderId } = useParams<{ orderId: string | undefined }>();

  const selectedOrder = useAppSelector(selectOrder(selectedOrderId));

  const history = useHistory();
  const dispatch = useDispatch();
  if (!isLoading && selectedOrderId !== undefined && selectedOrder === undefined) {
    dispatch(orderNotFound(selectedOrderId));
    history.push('/');
  }
  return null;
}

export function Dashboard() {
  const blotterShown = useMediaQuery([['(min-width: 1200px)', false]], true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBlotterShow(blotterShown));
  }, [blotterShown, dispatch]);
  return (
    <>
      <Notifications />
      <RedirectIfOrderNotFound />
      <Blotter />
      <LiveOrder />
    </>
  );
}
