import type { Strategy } from '@/types/api/sotw';

type SpeedStuff = Pick<Strategy, 'speed' | 'speedSlow' | 'speedFast' | 'speedNormal'>;

export function getCurrentSpeedPosition(strategy: SpeedStuff): number {
  const { speed, speedSlow, speedFast, speedNormal } = strategy;
  const speedVerySlow = speedSlow! / 2;
  const speedVeryFast = speedFast! * 2;

  const speeds = [speedVerySlow, speedSlow, speedNormal, speedFast, speedVeryFast];
  let intervalIndex = -1;
  for (const intervalSpeed of speeds) {
    if (intervalSpeed > speed) {
      break;
    }
    intervalIndex++;
  }

  if (intervalIndex === -1) {
    return 0;
  }

  if (intervalIndex >= speeds.length - 1) {
    return 100;
  }

  const intervalSpeedMin = speeds[intervalIndex];
  const intervalSpeedMax = speeds[intervalIndex + 1];

  const offset = ((speed - intervalSpeedMin) / (intervalSpeedMax - intervalSpeedMin)) * 25;

  return intervalIndex * 25 + offset;
}
