import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { logger } from '@/helpers/logger';

export const logApiMiddleware: Middleware = () => next => action => {
  if (isRejectedWithValue()(action)) {
    const {
      payload,
      error,
      meta: { arg },
    } = action;

    const endpoint = getProp(arg, 'endpointName') ?? 'unknown endpoint';
    logger.logInformation(
      'Error while calling web API on {endpoint_s}: {error_message_s} {payload_s} {args_s}',
      endpoint,
      error.message,
      JSON.stringify(payload),
      JSON.stringify(arg),
    );
  }
  return next(action);
};

function getProp(x: unknown, prop: string): unknown {
  if (typeof x === 'object' && hasOwnProperty(x, prop)) {
    return x[prop];
  }
  return undefined;
}

function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X | null,
  prop: Y,
): obj is X & Record<Y, unknown> {
  return obj?.hasOwnProperty(prop) ?? false;
}
