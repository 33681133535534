import { createSlice } from '@reduxjs/toolkit';
import { webApi } from '../api/web.api';
import type { AppState } from '../store';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@/state/hooks';
import type { AlgoOrder } from '@/types/api/algoOrders';

interface TcaState {
  selectedOrderId: string | undefined;
}

const initialState: TcaState = {
  selectedOrderId: undefined,
};

export const tcaSlice = createSlice({
  name: 'liveTca',
  initialState,
  reducers: {
    // orderSelected(state, action: PayloadAction<string>) {
    //   state.selectedOrderId = action.payload;
    // },
  },
});

// export const { orderSelected } = tcaSlice.actions;

export const selectOrders = (state: AppState) =>
  webApi.endpoints.getAlgoOrders.select()(state).data;
// export const selectSelectedOrderId = (state: RootState) => state.liveTca.selectedOrderId;
export const selectOrder = (orderId: string | undefined) => (state: AppState) => {
  const ordersState = selectOrders(state);
  if (orderId === undefined || ordersState === undefined) {
    return undefined;
  }
  return ordersState[orderId];
};

export function useSelectedOrder(): AlgoOrder | undefined {
  const { orderId: selectedOrderId } = useParams<{ orderId: string | undefined }>();
  return useAppSelector(selectOrder(selectedOrderId));
}
